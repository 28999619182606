@mixin border-radius($radius){
    border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius:$radius;
    -webkit-border-radius: $radius;
    
}
@mixin boxShadow ($horizontal:null, $vertical:null, $blur:null, $spread:null, $color:null, $inset:null){
    -moz-box-shadow: $horizontal $vertical $blur $spread $color $inset;
    -o-box-shadow: $horizontal $vertical $blur $spread $color $inset ;
    -webkit-box-shadow:$horizontal $vertical $blur $spread $color $inset ;
    box-shadow: $horizontal $vertical $blur $spread $color $inset;
}




@mixin keyframe ($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }

    @-moz-keyframes #{$animation_name} {
        @content;
    }

    @-o-keyframes #{$animation_name} {
        @content;
    }

    @keyframes #{$animation_name} {
        @content;
    }
}

@mixin animation ($delay, $duration, $animation, $timing, $iteration: 1, $direction: forward, $fillmode: forward) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-timing-function: $timing;
    -webkit-animation-iteration-count: $iteration;
    -webkit-animation-fill-mode: $fillmode;
    -webkit-animation-direction: $direction;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-timing-function: $timing;
    -moz-animation-iteration-count: $iteration;
    -moz-animation-fill-mode: $fillmode;
    -moz-animation-direction: $direction;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-timing-function: $timing;
    animation-iteration-count: $iteration;
    animation-fill-mode: $fillmode;
    animation-direction: $direction;
}
// box-shadow: 1px 1px 5px 1px white inset, -1px -1px 5px 1px white inset ;
@include keyframe (glowing-button) {
    0% {
        box-shadow: 0px 0px 0px 0px white inset, -0px -0px 0px 0px white inset ;
    }
    25% {
        box-shadow: .25px .25px 6px 1.25px white inset, -.25px -.25px 6px 1.25px white inset ;
    }
    50% {
        box-shadow: 3px 3px 6px 2px white inset, -3px -3px 6px 2px white inset ;
    }
    75% {
        box-shadow: .25px .25px 6px 1.25px white inset, -.25px -.25px 6px 1.25px white inset
    }
    100% {
        box-shadow: 0px 0px 0px 0px white inset, -0px -0px 0px 0px white inset ;
    }
}

@include keyframe(animation-slide-out-up) {
    0% {
        transform: translate(0,0);
    }

    100% {
        transform: translate(0,-100%);
    }
}

@include keyframe(animation-slide-out-down) {
    0% {
        transform: translate(0,0);
    }

    100% {
        transform: translate(0,100%);
    }
}

// Slide Out Left from Center
@include keyframe(animation-slide-out-left) {
    0% {
        transform: translate(0,0);
    }

    100% {
        transform: translate(-100%,0);
    }
}

// Slide out Right from Center
@include keyframe(animation-slide-out-right) {
    0% {
        transform: translate(0,0);
    }

    100% {
        transform: translate(100%,0);
    }
}

@include keyframe(animation-slide-in-up) {
    0% {
        transform: translate(0,100%);
    }

    100% {
        transform: translate(0,0);
    }
}

@include keyframe(animation-slide-in-down) {
    0% {
        transform: translate(0,-100%);
    }
    10%{
        transform: translate(0,-100%);
    }
    90% {
        transform: translate(0,0)  scaleX(.8);
    }
    95% {
        transform: translate(-10px,0) scaleX(.9);
    }
    100% {
        transform: translate(0,0);
    }
}

// Slide in Left to Center
@include keyframe(animation-slide-in-left) {
   0% {
        transform: translate(-100%,0);
    }
    10%{
        transform: translate(-100%,0);
    }
    90% {
        transform: translate(0,0)  scaleX(.8);
    }
    95% {
        transform: translate(-10px,0) scaleX(.9);
    }
    100% {
        transform: translate(0,0);
    }
}

// Slide in Right to Center
@include keyframe(animation-slide-in-right) {
    0% {
        transform: translate(100%,0);
    }

    100% {
        transform: translate(0,0);
    }
}