/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~ag-grid-community/src/styles/ag-grid.scss";
// @import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin.scss";
@import "./mixins";

ion-modal.wws-modal {
	--width: 80% !important;
	--height: 95% !important;
}

ion-modal.success-modal {
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}
ion-modal.success-modal::part(content) {
	height: 200px !important;
}

ion-modal.list-share {
	--width: 60% !important;
	--height: 90% !important;
}

ion-modal.category-modal {
	--width: 50% !important;
	--height: 80% !important;
}

ion-modal.input-modal {
	--width: 80% !important;
	--height: 80% !important;
}
.ag-theme-alpine {
	@include ag-theme-alpine();
}
.ag-theme-alpine-dark {
	@include ag-theme-alpine-dark();
}
.my-custom-class .modal-wrapper {
	width: 95%;
	height: 95%;
	max-width: 1600px;
	// overflow-y: scroll;
}
.my-import-class .modal-wrapper {
	width: 70%;
	height: 75%;
	max-width: 1600px;

	// overflow-y: scroll;
}

.document-modal .modal-wrapper {
	width: 95%;
	height: 95%;
	max-width: 1600px;
	overflow-y: hidden;
}
.category-modal .modal-wrapper {
	margin-top: 30px;
	margin-right: 700px;
	width: 35%;
	height: 65%;
	// max-width: 1600px;
	overflow-y: hidden;
}
.mat-tab-body-content {
	overflow-y: scroll;
}

.alert-custom-class {
	.alert-message {
		max-height: 700px;
	}
	.alert-wrapper {
		--height: auto;
		--max-height: 90%;
		--max-width: 500px;
	}
}
.success-modal .modal-wrapper {
	width: 28%;
	height: 40%;
	overflow: hidden;
}
.input-modal .modal-wrapper {
	width: 85%;
	max-width: 85%;
	height: 90%;
}
.change-modal .modal-wrapper {
	width: 45%;
	max-width: 45%;
	height: 70%;
}

.list-share .modal-wrapper {
	width: 70%;
	max-width: 70%;
	height: 80%;
}
.pointer {
	cursor: pointer;
}
.right {
	float: right;
}
.left {
	float: left;
}
.sm-txt {
	font-size: small;
}
.margin-right {
	margin-right: 20px;
}

.list-share-page {
	overflow: auto;
}